import {
  Box,
  Button,
  Grid,
  Heading,
  Icon,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Fragment } from 'react'
import { FaRegFilePdf } from 'react-icons/fa'

import { toCurrency, toDate } from '../../utils/format'
import { groupBy } from '../../utils/groupBy'
import Loader from '../Loader'
import { ReactComponent as Logo } from './empty.svg'
import { useFlightsQuery } from './query.generated'

type FlightListProps = {
  onSelectFlight?: (flight: number) => void
}

const FlightList: React.FC<FlightListProps> = ({ onSelectFlight }) => {
  const { data, loading, fetchMore } = useFlightsQuery({
    variables: {
      date: dayjs().format('YYYY-MM-DD'),
      enddate: dayjs().add(3, 'days').format('YYYY-MM-DD'),
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const handleLoadMore = () => {
    fetchMore({ variables: { offset: data?.legs.length } })
  }

  const handleSelectFlight = (id: number) => {
    onSelectFlight?.(id)
  }

  const groupedData = groupBy(
    data?.legs || [],
    (leg) => leg.departure.date.local
  )

  return (
    <Grid gap={4}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Stack direction="column" py={4} spacing={16}>
            {data?.legs.length === 0 ? (
              <VStack spacing={6}>
                <Box as={Logo} color="brand.500" h={64} w={64} />
                <VStack spacing={2}>
                  <Heading as="h3" size="md">
                    No flights available
                  </Heading>
                  <Text color="gray.500" maxWidth="sm" textAlign="center">
                    Sorry, but we do not have any flights available for you at
                    the moment. Please come back later.
                  </Text>
                </VStack>
              </VStack>
            ) : (
              Object.entries(groupedData).map(([date, legs]) => (
                <Box key={date}>
                  <Heading as="h1" color="brand.500" fontSize="xl">
                    {toDate(date, { displayWeekday: true })}
                  </Heading>
                  <Table marginTop={8} maxWidth="full" size="md">
                    <Thead>
                      <Tr>
                        <Th width="15%">Time</Th>
                        <Th width="35%">From</Th>
                        <Th width="35%">To</Th>
                        <Th width="15%">Aircraft</Th>
                        <Th isNumeric width={0}>
                          Price
                        </Th>
                        <Th width={0}></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {legs.map((leg) => (
                        <Fragment key={leg.id}>
                          <Tr>
                            <Td>
                              <Stack direction="column" spacing={2}>
                                <Text>{leg.departure.time.local}</Text>
                                <Text color="gray.500" fontSize="sm">
                                  {leg.destination.time.local}
                                </Text>
                              </Stack>
                            </Td>
                            <Td>
                              <Stack direction="column" spacing={2}>
                                <Text>{leg.departure.airport.name}</Text>
                                <Text color="gray.500" fontSize="sm">
                                  {leg.departure.airport.icao}
                                </Text>
                              </Stack>
                            </Td>
                            <Td>
                              <Stack direction="column" spacing={2}>
                                <Text>{leg.destination.airport.name}</Text>
                                <Text color="gray.500" fontSize="sm">
                                  {leg.destination.airport.icao}
                                </Text>
                              </Stack>
                            </Td>
                            <Td>
                              <Stack direction="column" spacing={2}>
                                <Text whiteSpace="nowrap">
                                  {leg.aircraft?.type}
                                </Text>
                                <Box
                                  alignItems="center"
                                  as="a"
                                  color="purple.500"
                                  display="flex"
                                  fontSize="sm"
                                  gap={1}
                                  href={require('./Datasheet.pdf')}
                                  target="_blank"
                                  rel="noreferrer"
                                  _hover={{ textDecoration: 'underline' }}
                                >
                                  <Icon as={FaRegFilePdf} /> Data Sheet
                                </Box>
                              </Stack>
                            </Td>
                            <Td isNumeric>
                              <Stack direction="column" spacing={1}>
                                <Text fontSize="lg" fontWeight="semibold">
                                  {toCurrency(
                                    leg.prices.net + leg.prices.luxuryTax
                                  )}
                                </Text>
                                <Text color="gray.500" fontSize="sm">
                                  per Person
                                </Text>
                              </Stack>
                            </Td>
                            <Td isNumeric>
                              <Button
                                colorScheme="brand"
                                onClick={() => handleSelectFlight(leg.id)}
                                variant="outline"
                              >
                                Book Now
                              </Button>
                            </Td>
                          </Tr>
                        </Fragment>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              ))
            )}
          </Stack>
        </>
      )}
      {!loading && false && (
        <Button onClick={handleLoadMore}>Load more...</Button>
      )}
    </Grid>
  )
}

export default FlightList
