import {
  Box,
  Button,
  Grid,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

import { toCurrency, toDate } from '../../utils/format'
import { groupBy } from '../../utils/groupBy'
import { useAuth } from '../AuthProvider'
import Cancellation from '../Cancellation'
import Loader from '../Loader'
import { ReactComponent as Logo } from './empty.svg'
import { useBookingsQuery } from './query.generated'

const Bookings: React.FC = () => {
  const { auth } = useAuth()
  const { data, loading, refetch } = useBookingsQuery({
    fetchPolicy: 'cache-and-network',
  })

  const handleCancel = () => {
    refetch()
  }

  const groupedData = groupBy(
    data?.bookedFlights || [],
    (flight) => flight.leg.departure.date.local
  )

  return (
    <Grid gap={4}>
      {loading ? (
        <Loader />
      ) : data?.bookedFlights.length === 0 ? (
        <>
          <VStack spacing={6}>
            <Box as={Logo} color="brand.500" h={64} w={64} />
            <VStack spacing={2}>
              <Heading as="h3" size="md">
                No bookings yet
              </Heading>
              {auth?.isPAD ? (
                <Text color="gray.500">
                  There are no booked flights at the moment
                </Text>
              ) : (
                <Text color="gray.500">Start booking your next flight now</Text>
              )}
            </VStack>
            {!auth?.isPAD && (
              <NavLink to="/flights">
                <Button colorScheme="brand" variant="solid">
                  Search flights
                </Button>
              </NavLink>
            )}
          </VStack>
        </>
      ) : (
        <>
          <Stack direction="column" py={4} spacing={16}>
            {Object.entries(groupedData)
              .sort()
              .reverse()
              .map(([date, bookedFlights]) => (
                <Box key={date}>
                  <Heading as="h1" color="brand.500" fontSize="xl">
                    {toDate(date, { displayWeekday: true })}
                  </Heading>
                  <Table marginTop={8} maxWidth="full" size="md">
                    <Thead>
                      <Tr>
                        <Th width="15%">Time</Th>
                        <Th width="25%">From</Th>
                        <Th width="25%">To</Th>
                        <Th isNumeric width="10%">
                          Booking number
                        </Th>
                        <Th isNumeric width={0}>
                          Price
                        </Th>
                        <Th width={0}></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {bookedFlights.map((bookedFlight) => (
                        <Fragment key={bookedFlight.id}>
                          <Tr>
                            <Td>
                              <Stack direction="column" spacing={2}>
                                <Text>
                                  {bookedFlight.leg.departure.time.local}
                                </Text>
                                <Text color="gray.500" fontSize="sm">
                                  {bookedFlight.leg.destination.time.local}
                                </Text>
                              </Stack>
                            </Td>
                            <Td>
                              <Stack direction="column" spacing={2}>
                                <Text>
                                  {bookedFlight.leg.departure.airport.name}
                                </Text>
                                <Text color="gray.500" fontSize="sm">
                                  {bookedFlight.leg.departure.airport.icao}
                                </Text>
                              </Stack>
                            </Td>
                            <Td>
                              <Stack direction="column" spacing={2}>
                                <Text>
                                  {bookedFlight.leg.destination.airport.name}
                                </Text>
                                <Text color="gray.500" fontSize="sm">
                                  {bookedFlight.leg.destination.airport.icao}
                                </Text>
                              </Stack>
                            </Td>
                            <Td isNumeric>
                              <Text fontSize="lg">
                                {bookedFlight.bookingNumber}
                              </Text>
                            </Td>
                            <Td isNumeric>
                              <Text fontSize="lg" fontWeight="semibold">
                                {toCurrency(
                                  bookedFlight.isCancelled
                                    ? 0
                                    : bookedFlight.total
                                )}
                              </Text>
                            </Td>
                            <Td isNumeric>
                              <Box display="flex" gap={4}>
                                <NavLink to={`/bookings/${bookedFlight.id}`}>
                                  <Button colorScheme="brand" variant="link">
                                    Details
                                  </Button>
                                </NavLink>

                                {auth?.isPAD && (
                                  <Cancellation
                                    isDisabled={bookedFlight.isCancelled}
                                    onCancel={handleCancel}
                                    flightId={bookedFlight.id}
                                  />
                                )}
                              </Box>
                            </Td>
                          </Tr>
                        </Fragment>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              ))}
          </Stack>
        </>
      )}
    </Grid>
  )
}

export default Bookings
